<section>
    <h1>Über mich</h1>
    <div class="grid">
        <div>
            <img src="/assets/images/me.jpg"/>
        </div>
        <div>

        </div>
    </div>
</section>