import { Component } from '@angular/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { faArrowCircleLeft, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { EGlobalPageType } from './app.interface';
import { ThreeStageService } from './components/threejs-stage/threejs-stage.service';
import { routeTransitionAnimations } from './route-transition-animations';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeTransitionAnimations]
})
export class AppComponent {
  title = 'CanvasTest';
  pagetype: EGlobalPageType = EGlobalPageType.Homepage
  backicon = faArrowCircleLeft
  get ishome():boolean {
    return this.pagetype === EGlobalPageType.Homepage
  }

  constructor(private router: Router, private threeStageService: ThreeStageService) {
    router.events.subscribe(val => {
        if(val instanceof NavigationStart)  {
          if(val.url != '/') {
            this.pagetype = EGlobalPageType.Subpage
            threeStageService.zoomIn(12);            
          } else {
            this.pagetype = EGlobalPageType.Homepage
            threeStageService.zoomOut(15);   
          }
        }
      });
}

  prepareRoute(outlet: RouterOutlet) {
    return outlet && 
      outlet.activatedRouteData && 
      outlet.activatedRouteData['animationState'];
   }

}
