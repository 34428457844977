import { Component, ElementRef, HostBinding, HostListener, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import * as THREE from 'three';
import { StageService } from './stage.service';


@Component({
  selector: 'app-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.scss']
})
export class StageComponent implements OnInit {
  @ViewChild('container') container: ElementRef<HTMLCanvasElement> | undefined;
  @HostBinding ('class.subpage') subpage = true;
  private scene: THREE.Scene = new THREE.Scene();
  private camera: THREE.PerspectiveCamera | undefined;
  
  private width: number = 0;
  private height: number = 0;

  constructor(private stageService: StageService, private router: Router) {
    router.events.subscribe(val => {
      if(val instanceof NavigationStart)  {
        this.subpage = val.url != '/'
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.stageService.setSize(window.innerWidth, window.innerHeight * .8)
  }

  ngOnInit(): void {
    
  }

 

  ngAfterViewInit(): void {
    this.onResize();
    const canavas: HTMLCanvasElement = this.stageService.init();
    this.container?.nativeElement.appendChild( canavas );
  }

}
