import { Injectable } from "@angular/core";
import * as THREE from "three";
import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import {MTLLoader} from 'three/examples/jsm/loaders/MTLLoader.js';
import {GLTF, GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import { NavigationStart, Router } from "@angular/router";


@Injectable()
export class StageService {
    private loader = new GLTFLoader();
    private camera = new THREE.PerspectiveCamera(30,  window.innerWidth / window.innerHeight, 10, 200);
    private cameraAngle: number = 0;
    private renderer = new THREE.WebGLRenderer( { antialias: true, alpha:true } );
    private scene: THREE.Scene = new THREE.Scene();
    private clock = new THREE.Clock();
    private mixer: undefined | THREE.AnimationMixer;
    private controls = new OrbitControls( this.camera, this.renderer.domElement );
    private sphere: any;
    private sphereLight: THREE.PointLight | undefined;
    private incraseSphereSize= true;
    private fullsize: boolean = true;
    private size = new THREE.Vector2;
    private requestID: number = 0;
    
    private get icosphere(): THREE.Mesh {
        return this.sphere?.children.find((child: any) => child.name === "Icosphere");
    }
    constructor(router: Router) {
        router.events.subscribe(val => {
            if(val instanceof NavigationStart)  {
              if(val.url != '/') {
                
                
                this.controls.enabled = false;
                this.camera.zoom = 3
                
              } else {
                this.controls.enabled = true;
              }
            }
          });
    }

    public init(): HTMLCanvasElement {
        this.loadModel();
        this.controls.enableZoom = false;
        this.controls.enablePan = false;
        this.controls.autoRotate = false;
        this.controls.autoRotateSpeed = 0.4;
        return this.renderer.domElement;
    }

    public shrink() {
        if(!this.fullsize) {
            this.renderer.setSize(200, 200);
        }
    }

    addParticles() {
        const geometry = new THREE.BufferGeometry();
            const vertices = [];
            const materials = [];
            for ( let i = 0; i < 10000; i ++ ) {

                let x = Math.random() * 200 - 100;
                let y = Math.random() * 200 - 100;
                let z = Math.random() * 200 - 100;
                if(z < 15 ) x = 100

                vertices.push( 100, y, 100 );

            }

            geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

            const parameters = [
                [[ 1.0, 0.2, 0.5 ], 20 ],
                [[ 0.95, 0.1, 0.5 ], 15 ],
                [[ 0.90, 0.05, 0.5 ], 10 ],
                [[ 0.85, 0, 0.5 ], 8 ],
                [[ 0.80, 0, 0.5 ] , 5 ]
            ];

            for ( let i = 0; i < parameters.length; i ++ ) {

                const color:any = parameters[ i ][ 0 ];
                const size = parameters[ i ][ 1 ];

                materials[ i ] = new THREE.PointsMaterial( { blending: THREE.AdditiveBlending, depthTest: false, transparent: true } );
                materials[ i ].color.setHSL( color[ 0 ], color[ 1 ], color[ 2 ] );

                const particles = new THREE.Points( geometry, materials[ i ] );

                particles.rotation.x = Math.random() * 6;
                particles.rotation.y = Math.random() * 6;
                particles.rotation.z = Math.random() * 6;

                this.scene.add( particles );

            }
    }

    public async loadModel() {
        //this.addParticles();
        this.loader.load('assets/3d/SphereNew.gltf', (gltf: GLTF) => {
            this.camera.position.set(0, 0, 15);
            this.camera.zoom = 2;
            this.camera.updateProjectionMatrix();
            console.log(typeof gltf);
            this.sphere = gltf.scene;
            console.log(this.sphere)
            this.scene.add( gltf.scene );
            

            this.mixer = new THREE.AnimationMixer( gltf.scene );
            
            gltf.animations.forEach( ( clip: THREE.AnimationClip) => {
                this.mixer?.clipAction( clip ).play();
            } );

            this.sphereLight = new THREE.PointLight( 0xd47479, 8, 100 );
            this.sphereLight.position.set( 0, 0, 0 );
            this.scene.add(this.sphereLight );
            

            const light = new THREE.DirectionalLight(0xd47479, 0.5);
            
            light.position.set(0, 0, -20);
            light.target.position.set(0, 0, 0);

            this.scene.add(light);
            this.scene.add(light.target);
            
            
            const ambientlight = new THREE.AmbientLight( 0x303030 ); // soft white light
            this.scene.add( ambientlight );

            

            this.controls.update()
            this.render();
        });
    }

    public setSize(width: number, height:number) {

        this.renderer.setSize(width,height);
        this.camera.aspect = width / height;
        

        this.camera.updateProjectionMatrix();
    }

    public render() {
            
            this.renderer.render(this.scene, this.camera);
            this.sphere.rotation.x += 0.005;
            this.sphere.rotation.z -= 0.005;

            if(this.incraseSphereSize && this.sphereLight) {
                this.sphere.scale.x += 0.001;
                this.sphere.scale.y += 0.001;
                this.sphere.scale.z += 0.001;
            } else if(this.sphereLight){
                this.sphere.scale.x -= 0.001;
                this.sphere.scale.y -= 0.001;
                this.sphere.scale.z -= 0.001;
            }

            if(this.sphere.scale.x < 0.8 || this.sphere.scale.x > 1.2) {
                this.incraseSphereSize = !this.incraseSphereSize;
            }

            var delta = this.clock.getDelta();
            if ( this.mixer ) this.mixer.update( delta );
            
            this.controls.update()
            this.requestID = requestAnimationFrame(this.render.bind(this));
    }
}