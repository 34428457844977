<app-threejs-stage></app-threejs-stage>
<a *ngIf="!ishome" routerLink="/" class="backlink"><fa-icon [icon]="backicon"></fa-icon></a>
<app-links></app-links>
<main [@triggerName]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
</main>
<nav>
  <ul>
      <li>
          <a routerLink="/blog">Blog</a>
      </li>
      <li>
          <a routerLink="/about">About</a>
      </li>
      <li>
          <a routerLink="/projects">Projects</a>
      </li>
      <li>
          <a routerLink="/contact">Contact</a>
      </li>
  </ul>
</nav>