import { JsonPipe } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import * as THREE from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { EnumPowerPreference, IThreeStageServiceInitOptions } from './threejs-stage.interface';
import { ThreeStageService } from './threejs-stage.service';

@Component({
  selector: 'app-threejs-stage',
  templateUrl: './threejs-stage.component.html',
  styleUrls: ['./threejs-stage.component.scss']
})
export class ThreejsStageComponent implements OnInit {
  @Input() width: string = 'auto';
  @Input() height: string = 'auto';
  @Input() initOptions: IThreeStageServiceInitOptions| undefined;
  @ViewChild('container') container: ElementRef<HTMLDivElement> | undefined;

  constructor(private threeStageService: ThreeStageService) { }

  
  @HostListener('window:resize', ['$event'])
  onResize() {
    if(this.container) {
      this.threeStageService.setSize(this.container.nativeElement.offsetWidth, this.container.nativeElement.offsetHeight);
    }
    
  }

  ngOnInit(): void {}

  parseDimensionString(value: string, fullsize: number) : number {

    if(value === "auto") { 
      return fullsize;
    }
    if(value.indexOf('%') > -1) {
      return fullsize * (parseInt(value.split('%')[0]) / 100);
    }
    if(value.indexOf('px') > -1) {
      return parseInt(value.split('px')[0]);
    }
    return 0;
  }

  init() {
    if(!this.initOptions) {
      const width = this.parseDimensionString(this.width, window.innerWidth)
      const height = this.parseDimensionString(this.height, window.innerHeight)
      this.initOptions = {
        perspectiveCameraOptions: {
          fov : 30,
          aspect : (width / height),
          near : 10,
          far : 200,
          position: new THREE.Vector3(0,0,15),
          zoom: 2
        },
        webGLRendererOptions: {
          antialias: true,
          alpha:true,
          powerPreference: EnumPowerPreference.high
        },
        orbitControlOption: {
          enableZoom: false,
          enablePan: false,
          autoRotate: true,
          autoRotateSpeed: 0.5,
        },
        height:  height,
        width: width,
      }
    }

    return this.initOptions;
  }

  ngAfterViewInit(): void {
    const options = this.init();
    const canavas: HTMLCanvasElement = this.threeStageService.init(options);
    this.threeStageService.loadModel('assets/3d/untitled2.gltf', (glft: GLTF) => {
      this.threeStageService.createAmbientLight('#404040', 'AmbientLight');
      this.threeStageService.createPointLight({color: '#d47479', position:new THREE.Vector3(0,0,0),intensity:5}, 'SphereLight')
      this.threeStageService.customAnimations.push({
          object: this.threeStageService.getObjectByName("Icosphere") || new THREE.Object3D(),
          rotation: {steps: new THREE.Vector3(-0.005, 0, -0.005)},
          scale:{from: 1, to: 1.4, steps: 0.001, mirror: true}
        });
      this.threeStageService.customAnimations.push({
        object: this.threeStageService.getObjectByName("SphereLight") || new THREE.Object3D(),
        
        intensity:{from: 3, to: 12, steps: 0.0225, mirror: true}
      });
      this.threeStageService.render();
      console.log(this.threeStageService.scene)
    });
    this.container?.nativeElement.appendChild( canavas );
  }
}
