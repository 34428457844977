import { Injectable } from "@angular/core";
import * as THREE from "three";
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import {GLTF, GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import { ICustomAnimation, ICustomAnimationPropertie, IPointLightOption, IRGBAColor, IThreeStageService, IThreeStageServiceInitOptions } from "./threejs-stage.interface";
import { AmbientLight, AnimationAction, AnimationMixer, Mesh, Object3D, PointLight, Vector3, WebGLRenderer } from "three";



@Injectable()
export class ThreeStageService implements IThreeStageService {
    loader = new GLTFLoader();
    clock = new THREE.Clock();
    scene= new THREE.Scene();

    renderer?:THREE.WebGLRenderer; 
    camera?: THREE.PerspectiveCamera;
    control?: OrbitControls;
    mixer?: THREE.AnimationMixer;

    models: Array<Mesh | Object3D> = [];
    lights: Array<AmbientLight | PointLight> = [];
    customAnimations: Array<ICustomAnimation> = [];
    options: IThreeStageServiceInitOptions | undefined;

    private zoomable?: () => void;
    private zoomTarget?: number;
    
    constructor() { }

    public init(options: IThreeStageServiceInitOptions): HTMLCanvasElement {
        this.options = options;
        // Init Camera
        this.camera = new THREE.PerspectiveCamera(
            options.perspectiveCameraOptions.fov,
            options.perspectiveCameraOptions.aspect || options.width / options.height,
            options.perspectiveCameraOptions.near,
            options.perspectiveCameraOptions.far);
        const pos = options.perspectiveCameraOptions.position;
        this.camera.position.set(pos.x, pos.y, pos.z);
        this.camera.zoom = options.perspectiveCameraOptions.zoom || 1;
        

        // Init Renderer
        this.renderer = new WebGLRenderer(options.webGLRendererOptions);
        this.renderer.setSize(options.width, options.height)

        // Init Control
        this.control = new OrbitControls( this.camera, this.renderer.domElement );
        if(options.orbitControlOption) {
            const oco = options.orbitControlOption;
            if(oco.enableZoom != undefined) this.control.enableZoom = oco.enableZoom;
            if(oco.enablePan != undefined) this.control.enablePan = oco.enablePan;
            if(oco.autoRotate != undefined) this.control.autoRotate = oco.autoRotate;
            if(oco.autoRotateSpeed != undefined) this.control.autoRotateSpeed = oco.autoRotateSpeed;
            this.control.update();
        }

        
        this.camera.updateProjectionMatrix();
        return this.renderer.domElement;
    }


    public async loadModel(path: string, callback: (gltf: GLTF) => void) {
        this.loader.load(path, (gltf: GLTF) => {
            console.log('Model was loaded');

            gltf.scene.children.forEach(child => {
                this.models.push(child);
            });

            this.scene.add( gltf.scene );
            this.control?.update();
            callback(gltf);
        });
    }

    public setSize(width: number, height:number) {
        this.renderer?.setSize(width,height);
        if(this.camera) {
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();
        }
    }

    createPointLight(options: IPointLightOption, name: string = '') {
        const light = new THREE.PointLight( 
            new THREE.Color( options.color ), 
            options.intensity, 
            options.distance, 
            options.decay? options.decay : undefined
        );

        light.position.set(options.position.x , options.position.y, options.position.z);
        light.name = name;
        this.lights.push(light);
        this.scene.add(light);
    }

    getObjectByName(name: string, array: Array<Object3D> = this.scene.children): Object3D | undefined {
        
        const hit = array.find((child) => {
            if(child.type === "Group") {
                const a = child.children.find((grpChild: Object3D) => grpChild.name === name)
                if(a) return a;
            }
            return child.name === name
        });

        return hit?.type === "Group" ? this.getObjectByName(name, hit.children) : hit;
    }

    createAmbientLight(color:string, name: string = '') {
        const value = new THREE.Color( color );
        const light = new THREE.AmbientLight(value);
        light.name = name;
        this.lights.push(light);
        this.scene.add( light );
    }

    rotate(object: Object3D, rotation: ICustomAnimationPropertie<THREE.Vector3>) {
        object.rotateX(rotation.steps?.x || 0);
        object.rotateY(rotation.steps?.y || 0);
        object.rotateZ(rotation.steps?.z || 0);
    }

    scaleEven(object: Object3D, to: number = 1, steps: number= 1): Vector3 {
        let diff = to - object.scale.x;
        let a = object.scale;
        let v3: THREE.Vector3;
        if(diff < steps && diff > steps * -1) {
            return new THREE.Vector3(to,to,to)
        } else {
            if(diff > 0) {
                return a.add(new THREE.Vector3(steps, steps,steps));
            } else if(diff < 0) {
                return a.sub(new THREE.Vector3(steps, steps,steps));
            }
        }
        return new THREE.Vector3();
    }

    mirrorAnimationValue(object:ICustomAnimationPropertie<any>): ICustomAnimationPropertie<any> {
        if(object.mirror) {
            const a = object.from;
            object.from = object.to;
            object.to = a;
        }
        
        return object;
    }

    animate():void {
        this.customAnimations = this.customAnimations.map((animation:ICustomAnimation) => {
            if(animation.rotation) {
                this.rotate(animation.object, animation.rotation);
            }

            if(animation.scale) {
                const v3 = this.scaleEven(animation.object, animation.scale.to, animation.scale.steps );
                
                animation.object.scale.set(v3.x, v3.y, v3.z);
                
                if(animation.scale.mirror && (animation.object.scale.x ===  (animation.scale.to || 1))) {
                    
                    animation.scale = this.mirrorAnimationValue(animation.scale);
                }
            }
            if(animation.intensity) {
                const light = (animation.object as PointLight);
                if(light.intensity < (animation.intensity?.to || 1)) {
                    light.intensity = 
                    ((animation.intensity?.to || 1) - light.intensity) < (animation.intensity?.steps || 1) ? 
                    (animation.intensity?.to || 1) : light.intensity + (animation.intensity?.steps || 1); 
                }
                if(light.intensity > (animation.intensity?.to || 1)) {
                    light.intensity = 
                    (light.intensity - (animation.intensity?.to || 1) ) < (animation.intensity?.steps || 1) ? 
                    (animation.intensity?.to || 1) : light.intensity - (animation.intensity?.steps || 1); 
                }
                console.log(animation.intensity?.to)
                if(animation.intensity?.mirror && (animation.intensity.to || 1) == light.intensity ) {
                    animation.intensity = this.mirrorAnimationValue(animation.intensity);
                }
            }

            if(animation.color) {
                const light = (animation.object as PointLight || AmbientLight);
                
                if(animation.color.to) {
                    const color = new THREE.Color(animation.color.to);
                    let r = color.r - light.color.r; 
                    let g = color.g - light.color.g; 
                    let b = color.b - light.color.b;

                    light.color = new THREE.Color(
                        r > 0 ? light.color.r + (0.5 /255) : light.color.r - (0.3 /255),
                        g > 0 ? light.color.g + (0.5 /255) : light.color.g - (0.3 /255),
                        b > 0 ? light.color.b + (0.5 /255) : light.color.b - (0.3 /255)
                    )

                    
                    if(light.color.getHex() === color.getHex()) {
                        
                        this.mirrorAnimationValue(animation.color);
                    }
                }
                
            }

            return animation;
        });
    }

    zoomIn(target: number) {
        if(this.models.length > 0) {
            this.zoomTarget = target;
            this.customAnimations = [];
            const ambientLight = this.getObjectByName('AmbientLight') as AmbientLight;
            
            console.log(this.getObjectByName('AmbientLight'));
            this.customAnimations.push({
                object: this.getObjectByName("SphereLight") || new THREE.Object3D(),
                
                intensity:{to: 0, steps: 0.2, mirror: false}
              });
            
            this.zoomable = () => {
                
                if(this.control) {
                    this.control.autoRotate = false;
                }
    
                if(this.camera) {
                    if(this.camera.position.z >= (this.zoomTarget || 3)) {
                        this.camera.position.setZ(this.camera.position.z - 0.1)
                        this.camera.zoom += 1;
                        this.getObjectByName("Icosphere")?.rotation.setFromVector3(new THREE.Vector3(23,1,15))
                        if(this.control) {
                            this.control.enableRotate = false;
                        }
                    } else {
                        this.zoomable = undefined;
                        this.zoomTarget = undefined;
                    }
                }
            } 
        } else {
            setTimeout(() => {this.zoomIn(target)}, 200)
        }
        
    }

    zoomOut(target: number) {
        this.zoomTarget = target;
        this.customAnimations.push({
            object: this.getObjectByName("Icosphere") || new THREE.Object3D(),
            rotation: {steps: new THREE.Vector3(-0.005, 0, -0.005)},
            scale:{from: 1, to: 1.4, steps: 0.001, mirror: true}
          });
          this.customAnimations.push({
            object: this.getObjectByName("SphereLight") || new THREE.Object3D(),
            
            intensity:{from: 3, to: 12, steps: 0.0225, mirror: true}
          });
        this.zoomable = () => {
            
            if(this.camera) {
                if(this.camera.position.z <= (this.zoomTarget || 2)) {
                    this.camera.position.setZ(this.camera.position.z + 0.1)
                    if(this.camera.zoom > (this.options?.perspectiveCameraOptions.zoom || 2)) {
                        this.camera.zoom = this.options?.perspectiveCameraOptions.zoom || 2;
                    }
                    if(this.control) {
                        this.control.enableRotate = true;
                    }
                    
                } else {
                    this.zoomable = undefined;
                    this.zoomTarget = undefined;
                }
            }
        } 
    }

    render():void {
        if(this.renderer && this.control && this.camera) {
            if(this.zoomable) {
                this.zoomable.bind(this)();
            }

            
            this.animate();
            this.renderer.render(this.scene, this.camera);
            this.camera.updateProjectionMatrix();
        }

        this.control?.update();
        requestAnimationFrame(this.render.bind(this));
    }
}