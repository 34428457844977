import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StageComponent } from './components/stage/stage.component';
import { StageService } from './components/stage/stage.service';
import { BlogComponent } from './components/blog/blog.component';
import { AboutComponent } from './components/about/about.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { ContactComponent } from './components/contact/contact.component';
import { StartComponent } from './components/start/start.component';
import { ThreejsStageComponent } from './components/threejs-stage/threejs-stage.component';
import { ThreeStageService } from './components/threejs-stage/threejs-stage.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LinksComponent } from './components/links/links.component';

@NgModule({
  declarations: [
    AppComponent,
    StageComponent,
    BlogComponent,
    AboutComponent,
    ProjectsComponent,
    ContactComponent,
    StartComponent,
    ThreejsStageComponent,
    LinksComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FontAwesomeModule,
    
  ],
  providers: [StageService, ThreeStageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
