import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { BlogComponent } from './components/blog/blog.component';
import { ContactComponent } from './components/contact/contact.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { StartComponent } from './components/start/start.component';

const routes: Routes = [
  { path: '', component: StartComponent, data: { animationState: 'One' } },
  { path: 'blog', component:  BlogComponent, data: { animationState: 'Two' }},
  { path: 'about', component:  AboutComponent, data: { animationState: 'Three' }},
  { path: 'projects', component:  ProjectsComponent, data: { animationState: 'Four' }},
  { path: 'contact', component:  ContactComponent, data: { animationState: 'Four' }},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
