import { AmbientLight, Mesh, Object3D, PointLight, Vector3 } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export interface IThreeStageService {
    loader: GLTFLoader;
    animationMixer?: THREE.AnimationMixer;
    camera?: THREE.PerspectiveCamera;
    renderer?: THREE.WebGLRenderer;
    scene?: THREE.Scene;
    clock: THREE.Clock;
    control?: OrbitControls;
    models: Array<Mesh | Object3D>;
    lights: Array<AmbientLight | PointLight>
    customAnimations: Array<ICustomAnimation>
}

export interface IPointLightOption {
    color: string;
    intensity?: number; 
    distance?: number; 
    decay?: number;
    position: Vector3;
}

export interface IPerspectiveCameraOptions {
    fov : number;
    aspect? : number;
    near : number;
    far : number;
    position: Vector3;
    zoom? :number;
}

export interface IOrbitControlOption {
    enableZoom?: boolean;
    enablePan?: boolean;
    autoRotate?: boolean;
    autoRotateSpeed?: number;
}

export interface IThreeStageServiceInitOptions {
    webGLRendererOptions?: IWebGLRendererOptions;
    perspectiveCameraOptions: IPerspectiveCameraOptions;
    height: number;
    width: number;
    orbitControlOption?: IOrbitControlOption;
}

export interface ICustomAnimationPropertie<T> {
    from?: T;
    to?: T;
    steps?: T
    mirror?: boolean;
}

export interface IRGBAColor{
    r: number;
    g: number;
    b: number;
    a: number;
}

export interface ICustomAnimation {
    object: Mesh | Object3D;
    rotation?: ICustomAnimationPropertie<Vector3>;
    position?: ICustomAnimationPropertie<Vector3>;
    scale?: ICustomAnimationPropertie<number>;
    intensity?: ICustomAnimationPropertie<number>;
    color?: ICustomAnimationPropertie<string>;
}



export enum EnumPowerPreference {
    high = "high-performance",
    low = "low-power",
    default ="default"
}

export interface IWebGLRendererOptions {
    antialias?: boolean;
    alpha?: boolean;
    powerPreference?: EnumPowerPreference
}

export interface IOrbitControlOptions {
    enableZoom?: boolean;
    enablePan?: boolean;
    autoRotate?: boolean;
    autoRotateSpeed?: number;
}